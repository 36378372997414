import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  RefineSnackbarProvider,
  CssBaseline,
  GlobalStyles,
  ReadyPage,
  ErrorComponent,
} from "@pankod/refine-mui";
import "./styles/style.css";
import { useTranslation } from "react-i18next";
import routerProvider from "@pankod/refine-react-router-v6";
import { DataProvider } from "@pankod/refine-strapi-v4";
import { ColorModeContextProvider } from "contexts";
import { Title, Sider, Layout, Header } from "components/layout";
import { authProvider, axiosInstance } from "./authProvider";
import { API_URL } from "./constants";

import { PoemsList } from "pages/poems/list";
import { PoemsShow } from "pages/poems/show";
import { PoemsEdit } from "pages/poems/edit";
import { PoemsCreate } from "pages/poems/create";
import { LoginPage } from "pages/login";
import { JournalCreate } from "pages/journal/create";
import { JournalList } from "pages/journal/list";
import { JournalEdit } from "pages/journal/edit";
import { JournalShow } from "pages/journal/show";

const App: React.FC = () => {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <ColorModeContextProvider>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
      <RefineSnackbarProvider>
        <Refine
          notificationProvider={notificationProvider}
          ReadyPage={ReadyPage}
          LoginPage={LoginPage}
          catchAll={<ErrorComponent />}
          i18nProvider={i18nProvider}
          routerProvider={routerProvider}
          authProvider={authProvider}
          dataProvider={DataProvider(API_URL + `/api`, axiosInstance as any)}
          Title={Title}
          Sider={Sider}
          Layout={Layout}
          Header={Header}
          resources={[
            {
              name: "poems",
              list: PoemsList,
              show: PoemsShow,
              edit: PoemsEdit,
              create: PoemsCreate,
              options: {
                label: "Poèmes",
              },
            },
            {
              name: "articles",
              create: JournalCreate,
              list: JournalList,
              edit: JournalEdit,
              show: JournalShow,
              options: {
                label: "Journal",
              },
            },
          ]}
        />
      </RefineSnackbarProvider>
    </ColorModeContextProvider>
  );
};

export default App;
