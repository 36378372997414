import { useShow } from "@pankod/refine-core";
import {
  Show,
  Stack,
  Typography,
  TagField,
  MarkdownField,
} from "@pankod/refine-mui";

import { IPoems } from "interfaces";

export const PoemsShow: React.FC = () => {
  const { queryResult } = useShow<IPoems>({
    metaData: {
      publicationState: "preview",
      populate: "*",
    },
  });

  const { data, isLoading } = queryResult;
  const record = data?.data;
  console.log(record);

  return (
    <Show
      title={<Typography variant="h6">Consulter le poème</Typography>}
      isLoading={isLoading}
    >
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          Titre
        </Typography>
        <Typography variant="body2">{record?.title}</Typography>
        <Typography variant="body1" fontWeight="bold">
          Contenu
        </Typography>
        <div style={{ fontFamily: "Sadpoem" }}>
          <MarkdownField value={record?.content} />
        </div>
        <Typography variant="body1" fontWeight="bold">
          État
        </Typography>
        <Typography variant="body2">
          <TagField value={record?.publishedAt ? "Publié" : "Brouillon"} />
        </Typography>
      </Stack>
      <Stack gap={1} marginTop={2}>
        <Typography variant="body1" fontWeight="bold">
          SEO
        </Typography>
        <Typography variant="body2" fontWeight="bold">
          Titre pour Google :
        </Typography>
        <Typography variant="body2">{record?.seo.metaTitle}</Typography>
        <Typography variant="body2" fontWeight="bold">
          Description pour Google :
        </Typography>
        <Typography variant="body2">{record?.seo.metaDescription}</Typography>
      </Stack>
    </Show>
  );
};
