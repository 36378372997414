import { useShow } from "@pankod/refine-core";
import { Show, Stack, Typography, MarkdownField } from "@pankod/refine-mui";

import { IPoems } from "interfaces";

export const JournalShow: React.FC = () => {
  const { queryResult } = useShow<IPoems>({
    metaData: {
      publicationState: "preview",
      populate: "*",
    },
  });

  const { data, isLoading } = queryResult;
  const record = data?.data;
  console.log(record);

  return (
    <Show
      title={<Typography variant="h6">Consulter cet article</Typography>}
      isLoading={isLoading}
    >
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          Titre
        </Typography>
        <Typography variant="body2">{record?.title}</Typography>
        <Typography variant="body1" fontWeight="bold">
          Contenu
        </Typography>
        <div style={{ fontFamily: "Sadpoem" }}>
          <MarkdownField value={record?.content} />
        </div>
      </Stack>
    </Show>
  );
};
