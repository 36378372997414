import { HttpError } from "@pankod/refine-core";
import { Edit, Box, TextField, Typography } from "@pankod/refine-mui";
import * as Showdown from "showdown";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import ReactMde from "react-mde";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IPoems } from "interfaces";
import { useState } from "react";

export const JournalEdit: React.FC = () => {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    control,
    formState: { errors },
  } = useForm<IPoems, HttpError, IPoems>({
    refineCoreProps: {
      metaData: {
        populate: "*",
      },
    },
  });
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
  });
  return (
    <Edit
      title={<Typography variant="h6">Éditer cet article</Typography>}
      isLoading={formLoading}
      saveButtonProps={{ ...saveButtonProps, children: "Enregistrer" }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("title")}
          error={!!errors?.title}
          helperText={errors.title?.message}
          margin="normal"
          fullWidth
          id="title"
          label="Titre"
          name="title"
          autoFocus
        />
        <Controller
          control={control}
          {...register("content", { required: "Contenu manquant" })}
          render={({ field }) => (
            <ReactMde
              value={field.value}
              onChange={(value) => field.onChange(value)}
              selectedTab={selectedTab}
              onTabChange={setSelectedTab}
              classes={{
                textArea: "sadpoem",
                preview: "sadpoem",
              }}
              generateMarkdownPreview={(markdown) =>
                Promise.resolve(converter.makeHtml(markdown))
              }
            />
          )}
        />
        {errors.content && (
          <Typography variant="body2" color="error">
            {errors.content.message}
          </Typography>
        )}
      </Box>
    </Edit>
  );
};
