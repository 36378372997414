import * as React from "react";
import { useForm } from "@pankod/refine-react-hook-form";
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  Container,
  Card,
  CardContent,
} from "@pankod/refine-mui";

import { styled } from "@mui/material/styles";

import {
  BaseRecord,
  HttpError,
  useLogin,
  useTranslate,
} from "@pankod/refine-core";

type ILoginForm = {
  username: string;
  password: string;
  remember?: boolean;
};

export const LoginPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<BaseRecord, HttpError, ILoginForm>({
    defaultValues: {
      remember: false,
      username: "",
      password: "",
    },
  });

  const { mutate: login, isLoading } = useLogin<ILoginForm>();
  const translate = useTranslate();

  const CssTextField = styled(TextField)({
    "& .MuiInputBase-root": {
      color: "black",
    },
    "& label": {
      color: "black",
    },
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  });

  return (
    <>
      <Box
        component="div"
        sx={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' viewBox='0 0 700 700' width='700' height='700' opacity='0.5'%3E%3Cdefs%3E%3Cfilter id='nnnoise-filter' x='-20%25' y='-20%25' width='140%25' height='140%25' filterUnits='objectBoundingBox' primitiveUnits='userSpaceOnUse' color-interpolation-filters='linearRGB'%3E%3CfeTurbulence type='turbulence' baseFrequency='0.05' numOctaves='4' seed='15' stitchTiles='stitch' x='0%25' y='0%25' width='100%25' height='100%25' result='turbulence'%3E%3C/feTurbulence%3E%3CfeSpecularLighting surfaceScale='22' specularConstant='1.1' specularExponent='20' lighting-color='hsl(0, 88%25, 40%25)' x='0%25' y='0%25' width='100%25' height='100%25' in='turbulence' result='specularLighting'%3E%3CfeDistantLight azimuth='3' elevation='84'%3E%3C/feDistantLight%3E%3C/feSpecularLighting%3E%3CfeColorMatrix type='saturate' values='0' x='0%25' y='0%25' width='100%25' height='100%25' in='specularLighting' result='colormatrix'%3E%3C/feColorMatrix%3E%3C/filter%3E%3C/defs%3E%3Crect width='700' height='700' fill='hsl(23, 5%25, 94%25)'%3E%3C/rect%3E%3Crect width='700' height='700' fill='hsl(0, 88%25, 40%25)' filter='url(%23nnnoise-filter)'%3E%3C/rect%3E%3C/svg%3E")`,
          backgroundRepeat: "repeat",
        }}
      >
        <Container
          component="main"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: { xs: 200, lg: "auto" },
              }}
            >
              <img
                src="/refine-black.svg"
                alt="logo Câblogrammes"
                style={{ width: "100%" }}
              />
            </Box>
            <Box maxWidth="400px" mt={4}>
              <Card
                sx={{
                  padding: 1,
                  background: "none",
                  border: "1px solid black",
                  borderRadius: "unset",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h4"
                    align="center"
                    sx={{
                      fontWeight: "700",
                      color: "text.primary",
                    }}
                  >
                    <Box component="span" color="black" fontFamily={"Sadpoem"}>
                      {translate("pages.login.title", "Câblogrammes ")}
                    </Box>
                  </Typography>
                  <Box
                    component="form"
                    onSubmit={handleSubmit((data) => {
                      login(data);
                    })}
                  >
                    <CssTextField
                      {...register("username", {
                        required: true,
                      })}
                      id="username"
                      margin="normal"
                      fullWidth
                      label={translate(
                        "pages.login.username",
                        "Nom d'utilisateur"
                      )}
                      name="username"
                      autoComplete="username"
                      autoFocus
                    />
                    <CssTextField
                      {...register("password", {
                        required: true,
                      })}
                      id="password"
                      margin="normal"
                      fullWidth
                      label={translate("pages.login.password", "Mot de passe")}
                      helperText={errors?.password?.message}
                      type="password"
                      placeholder="●●●●●●●●"
                      autoComplete="current-password"
                    />
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        sx={{
                          span: {
                            fontSize: "12px",
                            color: "black",
                          },
                        }}
                        color="secondary"
                        control={
                          <Checkbox
                            size="small"
                            id="remember"
                            sx={{
                              "&.Mui-checked": {
                                color: "black",
                              },
                            }}
                            {...register("remember")}
                          />
                        }
                        label={translate(
                          "pages.login.remember",
                          "Se souvenir de moi"
                        )}
                      />
                      {/* <Link
                        href="#"
                        sx={{
                          color: "black",
                          textDecoration: "none",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                          }}
                        >
                          {translate("pages.login.forgotPassword", "Forgot?")}
                        </Typography>
                      </Link> */}
                    </Box>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{
                        my: "8px",
                        color: "black",
                        background: "transparent",
                        borderRadius: "unset",
                        boxShadow: "none",
                        border: "1px solid black",
                        "&:hover": {
                          background: "transparent",
                        },
                      }}
                      disabled={isLoading}
                    >
                      {translate("pages.login.signin", "Se connecter")}
                    </Button>
                    {/* <Link
                      href="#"
                      sx={{
                        textDecoration: "none",
                        display: "flex",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          letterSpacing: "-0.04px",
                          lineHeight: "38px",
                          color: "black",
                        }}
                      >
                        {translate(
                          "pages.login.noAccount",
                          "Don’t have an account? "
                        )}{" "}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "12px",
                          letterSpacing: "-0.04px",
                          lineHeight: "38px",
                          color: "primary.main",
                          marginLeft: "2px",
                        }}
                      >
                        {translate("pages.login.signup", " Sign up")}
                      </Typography>
                    </Link> */}
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};
