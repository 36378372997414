import React from "react";
import {
  useDataGrid,
  DataGrid,
  GridColumns,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  Stack,
} from "@pankod/refine-mui";

import { IPoems } from "interfaces";

export const PoemsList: React.FC = () => {
  const { dataGridProps } = useDataGrid<IPoems>({
    initialSorter: [{ field: "title", order: "asc" }],
    metaData: {
      publicationState: "preview",
      populate: "*",
    },
  });

  console.log(dataGridProps);

  const columns = React.useMemo<GridColumns<IPoems>>(
    () => [
      {
        field: "title",
        headerName: "Titre",
      },
      {
        field: "publishedAt",
        headerName: "État",
        type: "text",
        // if publishedAt is null, then display "draft"
        renderCell: function (value) {
          return value?.value ? "Publié" : "Brouillon";
        },
      },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 250,
        renderCell: function render(params) {
          return (
            <Stack direction="row">
              <EditButton hideText recordItemId={params.row.id} />
              <ShowButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        },
      },
    ],
    []
  );

  return (
    <List
      createButtonProps={{ children: "Nouveau poème" }}
      wrapperProps={{
        sx: {
          marginTop: "3rem",
        },
      }}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        density="compact"
      />
    </List>
  );
};
