import React from "react";
import {
  useDataGrid,
  DataGrid,
  GridColumns,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  Stack,
} from "@pankod/refine-mui";

import { IPoems } from "interfaces";

export const JournalList: React.FC = () => {
  const { dataGridProps } = useDataGrid<IPoems>({
    initialSorter: [{ field: "createdAt", order: "desc" }],
  });

  console.log(dataGridProps);

  const columns = React.useMemo<GridColumns<IPoems>>(
    () => [
      {
        field: "title",
        headerName: "Titre",
        // get value from createdAt field
        valueGetter: (params) => params.row.createdAt,
        // format value
        valueFormatter: (params) => params.value.toLocaleString(),
        // if title is empty, show value from createdAt field
        renderCell: (params) =>
          params.row.title ||
          new Date(params.value).toLocaleString("fr-FR", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          }),
      },
      {
        field: "createdAt",
        headerName: "Date de publication",
      },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 250,
        renderCell: function render(params) {
          return (
            <Stack direction="row">
              <EditButton hideText recordItemId={params.row.id} />
              <ShowButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        },
      },
    ],
    []
  );

  return (
    <List
      createButtonProps={{ children: "Nouvel article" }}
      wrapperProps={{
        sx: {
          marginTop: "3rem",
        },
      }}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        density="compact"
      />
    </List>
  );
};
